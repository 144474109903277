$(function(){

  var ua = navigator.userAgent;
  if(ua.match(/MSIE/) || ua.match(/Trident/)){
  	$("body").addClass('ie11');
  }

	var $win = $(window);
	var winW = function(){
		return $win.width();
	}
	var winH = function(){
		return $win.height();
	}
	var $side = $('.box-side');
	var $openMenu = $('.btn-menu');
	var $closeMenu = $('.btn-close');
	var $footer = $('.footer');
	var $main = $('.main');
	var $comment = $('.comment');
	var $boxComment = $('.box-comment');
	var $closeComment = $('.comment-close');
	var $wrapper = $('#wrapper');
	var $wrapYoutube = $('.wrap-youtube');
	var $youtube = $('.youtube-inner');
	var $btnYoutube = $('.btn-youtube');

	if($('.fix-height').length > 0){
		var contentHeight = $footer.height()+$main.height();
		if(winH() > contentHeight){
			var setH = winH() - contentHeight;
			var nowH = $('.fix-height').height();
			setH = nowH + setH;
			if(setH > 800){
				setH = 800;
			}
			$('.fix-height').height(setH)
		}
	}

	$wrapper.addClass('show');
	$openMenu.click(function(){
		$openMenu.addClass('hide');
		$closeMenu.removeClass('hide');
		$side.addClass('open');
		$footer.addClass('nav-open');
		$main.addClass('nav-open');
		$wrapper.addClass('nav-open');
	});

	$closeMenu.click(function(){
		$closeMenu.addClass('hide');
		$openMenu.removeClass('hide');
		$side.removeClass('open');
		$footer.removeClass('nav-open');
		$main.removeClass('nav-open');
		$wrapper.removeClass('nav-open');
	});

	if($comment.length > 0){
		$comment.click(function(){
			var target = ".box-comment." + $(this).data('comment');
			var tempH = $main.height() + $footer.height() + 10;
			if(winH() > tempH) {
				var setH = winH();
			}else {
				var setH = tempH;
			}
			$(window).scrollTop(0);
			$(target).height(setH).addClass('show').fadeIn(function(){
				
			});

		});

		$closeComment.click(function(){
			$('.box-comment.show').fadeOut();
		});
	}

	// youtube

	var $wrapYoutube = $('.wrap-youtube');
	var $youtube = $('.youtube-inner');

	$btnYoutube.on("click",function(){
		var youtubeID = $(this).data('youtube');
		$wrapYoutube.fadeIn(800,function(){
			var html = '<iframe width="853" height="480" src="https://www.youtube.com/embed/'+ youtubeID +'?rel=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>';
			$youtube.html(html);
		});
	});

	$wrapYoutube.click(function(){
		$wrapYoutube.fadeOut(800,function(){
			$youtube.html("");			
		});
	});

	// ticker

	// if($('.box-side-news').length > 0){
	// 	$.ajax({
	// 	    type: 'GET',
	// 	    url: '../../ticker',
	// 	    dataType: 'html',
	// 	    success: function(data) {
	// 	    $('.box-side-news ul').html($(data));
	// 	  }	
	// 	});		
	// }

	if($('.header').length > 0){
		var headerState = false;
		var headerThreshold = 30;
		checkScroll();
		$(window).scroll(checkScroll);
	}

	function checkScroll(){
		if(!headerState){
			var nowScrollTop = $(window).scrollTop();
			if(nowScrollTop > headerThreshold){
				headerState = true;
				$('.header').addClass('scroll');
				resetTimer();
			}else if(nowScrollTop == 0){
				headerState = true;
				$('.header').removeClass('scroll');
				resetTimer();
			}
		}
	}

	function resetTimer(){
		setTimeout(function(){
			headerState = false;
			checkScroll();
		},300);
	}

	// function ticker(){
	// 	var $ticker = $('.box-side-news');
	// 	var num = $ticker.length;
	// 	var now = 0;
	// 	var auto;
	// 	function moveTicker(){
	// 		auto = setTimeout(function(){
	// 			$ticker.removeClass('hide')
	// 			$ticker.eq(now).removeClass('show').addClass('hide');
	// 			now++;
	// 			if( now == num) {
	// 				now = 0;
	// 			}
	// 			$ticker.eq(now).addClass('show');
	// 			moveTicker();
	// 		},5000);
	// 	}
	// 	if(num > 1){
	// 		moveTicker();
	// 	}
	// }

	if($('.pic-story-main').length > 0){
		storyPic();
	}

	function storyPic(){
		var $main = $('.pic-story-main');
		var $list = $('.pic-story-list li');
		var storymoving = false;
		$list.click(function(){
			if(!$(this).hasClass('current') && !storymoving){
				$list.removeClass('current');
				$(this).addClass('current')
				storymoving = true;
				var imageSrc = $(this).find('img').attr('src');
				$main.css('background-image','url('+imageSrc+')');
				$main.addClass('hide');
				setTimeout(function(){
					$main.find('img').attr('src',imageSrc);
					$main.removeClass('hide');
					storymoving = false;
				},510);
			}
		});
	}

	if($('.entry-list').length > 0){
		entryListLink();
	}

	function entryListLink(){
		$('.entry-list').each(function(){
			var entryLink = $(this).find('a').attr('href');
			$(this).on('click',function(){
				location.href = entryLink;
			})
		});
	}

	


	// 登場人物
	var $charactor = $('#box-charactor');
	if($charactor.length > 0){

		var hash = location.hash;
		if(hash == ""){
			hash = "harada";
			$('.thumbs-harada').addClass('current')
			charaChange();
		}else{
			hash = hash.split('#');
			var targetThumb = ".thumbs-"+hash[1];
			$(targetThumb).addClass('current')
			charaChange();
		}

		var $thumb = $('.thumbs-charactor');

		$thumb.click(function(){
			if(!$(this).hasClass('current')){
				$thumb.removeClass('current');
				$(this).addClass('current');
				location.hash = $(this).data('hash');
			}
		});

		function charaChange(){
			$('.box-chara-inner').removeClass('show');
			if($('.thumbs-harada').hasClass('current')){
				$('.box-harada').addClass('show');
			}else if($('.thumbs-nagakura').hasClass('current')){
				$('.box-nagakura').addClass('show');
			}else if($('.thumbs-seiha').hasClass('current')){
				$('.box-seiha').addClass('show');
			}else if($('.thumbs-kadowaki').hasClass('current')){
				$('.box-kadowaki').addClass('show');
			}else if($('.thumbs-mizugaki').hasClass('current')){
				$('.box-mizugaki').addClass('show');
			}else if($('.thumbs-kaionji').hasClass('current')){
				$('.box-kaionji').addClass('show');
			}else if($('.thumbs-sawaguchi').hasClass('current')){
				$('.box-sawaguchi').addClass('show');
			}else if($('.thumbs-higashidani').hasClass('current')){
				$('.box-higashidani').addClass('show');
			}else if($('.thumbs-nobunishi').hasClass('current')){
				$('.box-nobunishi').addClass('show');
			}else if($('.thumbs-tomura').hasClass('current')){
				$('.box-tomura').addClass('show');
			}else if($('.thumbs-yoshisada').hasClass('current')){
				$('.box-yoshisada').addClass('show');
			}
			sceneChange();
		}

		window.addEventListener("hashchange", charaChange, false);

		var $arrowL = $('.btn-arrow-left');
		var $arrowR = $('.btn-arrow-right');

		$arrowL.click(function(){
			charChange(-1);
		});

		$arrowR.click(function(){
			charChange(1);
		});

		function charChange(_num){
			var $list = $('.box-chara-inner.show .charactor-list li');
			var listNum = $list.length;
			var currentNum = $list.index($('.box-chara-inner.show .charactor-list li.show'));
			var nextNum = currentNum + _num;
			if(nextNum == -1){
				nextNum = listNum -1;
			}else if(nextNum == listNum){
				nextNum = 0;
			}
			$list.removeClass('show');
			$list.eq(nextNum).addClass('show');
		}

		function sceneChange(){
			var $this = $('.box-chara-inner.show');
			var $list = $('.box-chara-inner.show dt li');
			var listNum = $list.length;
			var nowNum = 0;
			if(listNum > 1){
				$list.removeClass('show');
				$list.eq(0).addClass('show');
				function sceneChangeloop(){
					setTimeout(function(){
						$list.eq(nowNum).removeClass('show');
						nowNum++;
						if(nowNum == listNum){
							nowNum = 0;
						}
						$list.eq(nowNum).addClass('show');
						if($this.hasClass('show')){
							sceneChangeloop();
						}
					},3000);
				}
				sceneChangeloop();
			}
			var isFirst = $('.box-chara-inner.show').addClass('slide');

		}


	}


	// カスタムスクロール
	
	$.fn.customScroll = function(options){
		
		return this.each(function(){

			var $this = $(this);

			var winScrollTop;
			var scrollStop_flg;
			var scrollPos     = 0;
			var scrollPosKnob = 0;
			var scrollMax;
			var scrollKnobMax;
			var SCROLLSPEED   = 10;
			var eY;
			var boxHeight;
			var scrollHeight;
			var nobHeight;

			// 初期設定

			var defaults = {
				scrollWidth    : '5px',
				scrollNobColor : "#7ecef4",
				scrollBgColor  : "#eee",
				nobRadius      : "0px"
			};

			var setting = $.extend(defaults,options);

			// オプションを変数に格納する
			var scrollWidth = setting.scrollWidth;
			var scrollNobColor   = setting.scrollNobColor;
			var scrollBgColor    = setting.scrollBgColor;
			var nobRadius   = setting.nobRadius;

			// スクロール用のパーツを追加する
			$this.wrapInner('<div class="custom-scroll"></div>');
			$this.wrapInner('<div class="custom-scroll-box"></div>');
			$this.find(".custom-scroll-box").append('<div class="custom-scroll-bg" style="width:'+ scrollWidth +'; background-color:'+ scrollBgColor +';"></div>');
			$this.find(".custom-scroll-box").append('<div class="custom-scroll-knob" style="width:'+ scrollWidth +'; background-color:'+ scrollNobColor +'; border-radius:'+ nobRadius +';"></div>');

			// スクロール大枠の高さを求める
			// var boxHeight = $this.height();

			checkHeidht();
			$(window).resize(function(){
				checkHeidht();
			});
			function checkHeidht(){

				boxHeight = $(window).height()-135;
				$this.height(boxHeight);
				// スクロール内容の高さを求める
				
				scrollHeight = $this.find('.custom-scroll').outerHeight(true);


				if( scrollHeight <  boxHeight){
					scrollPos = 0;
					$this.find('.custom-scroll').css({ top : scrollPos });
					$this.find('.custom-scroll-knob').css({	top : scrollPos });
					$this.find('.custom-scroll-bg ,.custom-scroll-knob').hide();
				}else{
					$this.find('.custom-scroll-bg ,.custom-scroll-knob').show();
				}

				// ブラウザのもともとあるスクロールを非表示に

				$this.css({
					overflow:"hidden"
				});

				// ノブの高さを算出
				nobHeight = parseInt(boxHeight*(boxHeight / scrollHeight));
				$this.find(".custom-scroll-knob").css({
					height : nobHeight
				});

				scrollMax = scrollHeight - boxHeight ;
				scrollKnobMax = boxHeight - nobHeight;

			}

			scrollEvent();


			function scrollEvent(){

				// マウスホイールイベント

				$this.find('.custom-scroll').mousewheel(function(event, delta, deltaX, deltaY) {
					if( scrollHeight <  boxHeight){ return false ;}
					if(delta > 0){
						scrollPos = scrollPos + SCROLLSPEED;
					}else{
						scrollPos = scrollPos - SCROLLSPEED;
					}

					scrollPosKnob = scrollPos*(boxHeight / scrollHeight);

					if(-scrollMax > scrollPos){
						scrollPos = -scrollMax;
					}else if(scrollPos > 0){
						scrollPos = 0;
					}

					$this.find('.custom-scroll').css({
						top : scrollPos
					});

					$this.find('.custom-scroll-knob').css({
						top : -scrollPosKnob
					});

					event.stopPropagation();
					event.preventDefault();
				});

				// ドラッグイベント

				$this.find('.custom-scroll-knob').mousedown(function(event){
					
					$(this).addClass("drag");

					eY = event.pageY - $(this).css("top").replace(/px/,"");
					
					$(document).mousemove(function(event){

						scrollPosKnob = event.pageY - eY;

						if(scrollKnobMax < scrollPosKnob){
							 scrollPosKnob = scrollKnobMax;
						}else if(scrollPosKnob < 0){
							 scrollPosKnob = 0;
						}

						scrollPos = -scrollPosKnob*(scrollHeight/boxHeight);					

						$("div.drag").css({
							top:scrollPosKnob
						});

						$this.find('.custom-scroll').css({
							top : scrollPos
						});

					});
					
					$(document).mouseup(function(event){
						$(document).unbind("mousemove");
						$("div.drag").removeClass("drag");
					});
				});


				// var startY;
	   //    //イベントの取得
	   //    $this.find(".custom-scroll").on("touchstart touchmove touchend", touchHandler);

	   //    function touchHandler(e) {
	   //      var touch = e.originalEvent.touches[0];


	   //      if (e.type == "touchstart") {

	   //          //タッチ開始時のX座標（ startY ）
	   //          startY = touch.pageY;
	            
	   //      } else if (e.type == "touchmove") { //画面をスライドした
	            
	   //        //スライド時のY座標 - 開始時のY座標 = 移動距離（ diffY ）
	   //        diffY =  touch.pageY - startY ;

				// 			scrollPos = scrollPos + diffY;

				// 		if(-scrollMax > scrollPos){
				// 			scrollPos = -scrollMax;
				// 		}else if(scrollPos > 0){
				// 			scrollPos = 0;
				// 		}
				// 		scrollPosKnob = scrollPos*(boxHeight / scrollHeight);

				// 		$this.find('.custom-scroll').css({
				// 			top : scrollPos
				// 		});

				// 		$this.find('.custom-scroll-knob').css({
				// 			top : -scrollPosKnob
				// 		});

				// 		event.stopPropagation();
				// 		event.preventDefault();

	   //      }
	   //    }


			}


		});
	};

// $(".header-inner").customScroll();


	// ブルーレイ
	// var $products = $('#box-products');
	// if($products.length > 0){

	// 	getHash();

	// 	function getHash(){
	// 		var hash = location.hash;
	// 		if(hash == ""){
	// 			hash = "product1";
	// 			$('.list-product1').addClass('current')
	// 			productChange(hash);
	// 		}else{
	// 			hash = hash.split('#');
	// 			var targetThumb = ".list-"+hash[1];
	// 			$(targetThumb).addClass('current')
	// 			productChange(hash[1]);
	// 		}			
	// 	}
		
	// 	var $thumb = $('.list-product li');

	// 	$thumb.click(function(){
	// 		if(!$(this).hasClass('current')){
	// 			$thumb.removeClass('current');
	// 			$(this).addClass('current');
	// 			// location.hash = $(this).find('a').attr('href');
	// 			// return false;
	// 		}
	// 	});

	// 	function productChange(_hash){
	// 		$('.box-product').removeClass('show');
	// 		var targetProduct = "#box-"+_hash;
	// 		$(targetProduct).addClass('show')
	// 	}

	// 	window.addEventListener("hashchange", getHash, false);

	// }



});



